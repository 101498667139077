$(document).ready(function () {
    // Back to top button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.back-to-top').removeClass('d-none').addClass('d-flex');
        } else {
            $('.back-to-top').removeClass('d-flex').addClass('d-none');
        }
    });

    $('.back-to-top').click(function () {
        $('html, body').animate({scrollTop: 0}, 1500);
        return false;
    });

    $(".phoneInput").intlTelInput({
        hiddenInput: "Mobile",
        utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
        nationalMode: true,
        separateDialCode: true,
        initialCountry: "tr",
        preferredCountries: [
            "SA",
            "TR",
            "KW",
            "IQ",
            "QA",
            "BH",
            "SY",
            "EG",
            "OM",
        ],
    });

    $(".number-only").keydown(function (event) {
        // Allow: backspace, delete, tab, escape, and enter
        if (
            event.keyCode == 46 ||
            event.keyCode == 8 ||
            event.keyCode == 9 ||
            event.keyCode == 27 ||
            event.keyCode == 13 ||
            // Allow: Ctrl+A
            (event.keyCode == 65 && event.ctrlKey === true) ||
            // Allow: home, end, left, right
            (event.keyCode >= 35 && event.keyCode <= 39)
        ) {
            if ($(this).val().length > 6 && $(this).val().length < 15) {
                $(this).parentsUntil('.contact-form').find(':input[type="submit"]').prop("disabled", false);
            } else {
                $(this).parentsUntil('.contact-form').find(':input[type="submit"]').prop("disabled", true);
            }
            return;
        } else {
            // Ensure that it is a number and stop the keypress
            if (
                event.shiftKey ||
                ((event.keyCode < 48 || event.keyCode > 57) &&
                    (event.keyCode < 96 || event.keyCode > 105))
            ) {
                event.preventDefault();
            }
        }

        if ($(this).val().length > 6 && $(this).val().length < 15) {
            $(this).parentsUntil('.contact-form').find(':input[type="submit"]').prop("disabled", false);
        } else {
            $(this).parentsUntil('.contact-form').find(':input[type="submit"]').prop("disabled", true);
        }
    });
});

